import React, { Component } from 'react';
import { Transition, animated } from 'react-spring';
import Component1 from './components/Component1';
import Component2 from './components/Component2';
import Component3 from './components/Component3';
import Component4 from './components/Component4';
import './style.css';

class App extends Component {


  state = {
    showComponent3: false
  }
  toggle = e => this.setState({ showComponent3: !this.state.showComponent3});

  state = {
    showComponent4: false
  }
  toggle4 = e => this.setState({ showComponent4: !this.state.showComponent4});


  render() {
    return (
      <div className="App">
        <Component1 />
          <Transition
            config={window.scrollTo({ top: 550, behavior: 'smooth' })}
            native
            items={this.state.showComponent4}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0}}
            >
              {show => show && (props => (
                <animated.div style={props}>
                  <Component4 />
                </animated.div>
              )) }
          </Transition >

        <Transition
          config={window.scrollTo({ top: 550, behavior: 'smooth' })}
          native
          items={this.state.showComponent3}
          from={{ opacity: 0}}
          enter={{ opacity: 1}}
          leave={{ opacity: 0}}
          >
            {show => show && (props => (
              <animated.div style={props}>
                <Component3 toggle4={this.toggle4}/>
              </animated.div>
            )) }
        </Transition >
        <Component2 toggle={this.toggle}/>

      </div>
    );
  }
}

export default App;
