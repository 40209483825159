import React from 'react'

export default function Component4() {
  return (
    <div style={c4Style}>
    <section id="main">
        <div class="container">
          <article id="main-col">
            <h1 class="page-title">Courses</h1>
            <ul id="courses">
              <li>
                <h3>Course 1</h3>
                <p>Suspendisse turpis mi, scelerisque sit amet cursus ut, vestibulum at dui. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam vestibulum dui vitae volutpat euismod.</p>
                <p>Details</p>
              </li>
              <li>
                <h3>Course 2</h3>
                <p>Suspendisse sagittis ultricies enim, vel dapibus tellus tempus ac. Phasellus sodales est sed augue gravida, ut dignissim felis laoreet.</p>
                <p>Details</p>
              </li>
            </ul>
          </article>


          <aside id="sidebar">
            <div class="dark">
            <h3>Get a Quote</h3>
              <form class="quote">
                <div>
                  <label>Name</label><br/>
                  <input type="text" placeholder="Name"/>
                </div>
                <div>
                  <label>Email</label><br/>
                  <input type="email" placeholder="Email Adress"/>
                </div>
                <div>
                  <label>Message</label><br/>
                  <textarea placeholder="Message"></textarea>
                </div>
                <button type="submit" class="button_1">Send</button>
              </form>
            </div>
          </aside>
        </div>
      </section>
    </div>
  )
}
const c4Style = {
  background: 'rgba(138, 170, 229, 0.9)',
  padding: '0' ,
  margin: '0 0 50px 0'
};
