import React, { Component } from 'react';
import { Spring } from 'react-spring';




export class Component3 extends Component {
  render() {
    return (
      <Spring
        from={{ opacity: 0, marginTop:-400}}
        to={{opacity: 1, marginTop:0}}
        config={{ delay: 1000, duration: 1000}}
        >
        {props => (
          <div style={props}>
            <div style={c2Style}>
            <section id="main">
              <div class="container">
                <article id="main-col">
                  <h1 class="page-title">About us</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut posuere felis massa, id commodo urna luctus sagittis. Proin metus elit, cursus sed velit et, mollis sagittis mi. Fusce ut massa iaculis, placerat lacus ac, auctor purus. Morbi porttitor lectus ut dui scelerisque, et pellentesque erat laoreet. Aenean maximus arcu massa, vel porta odio accumsan vitae. Donec malesuada magna sed fringilla placerat. Nunc consectetur eget velit iaculis posuere. Nunc ultrices quis augue at semper. Etiam euismod felis sit amet malesuada faucibus.
                    </p>
                    <p>
          Cras ut leo iaculis, sagittis ex eget, accumsan justo. Morbi pellentesque quam dui, at iaculis eros dignissim nec. Maecenas porta viverra nisl ut tempus. Vivamus eu imperdiet mi, non tincidunt mauris. Donec in pretium nibh, in fermentum ex. Fusce dignissim faucibus erat, et scelerisque tortor tempor interdum. Maecenas maximus feugiat nibh et posuere. Curabitur ac tellus id odio mattis consequat a in nulla. Vivamus faucibus magna lacus, non ultricies leo facilisis sed. Suspendisse interdum sapien ligula, non pretium elit eleifend vitae.</p>
                </article>

                <aside id="sidebar">
                  <div class="dark">
                  <h3>Why do your engineers need this?</h3>
                  <p>Cras ut leo iaculis, sagittis ex eget, accumsan justo. Morbi pellentesque quam dui, at
                  iaculis eros dignissim nec. Maecenas porta viverra nisl ut tempus. Vivamus eu imperdiet mi, non tincidunt mauris.</p>
                  </div>
                </aside>
              <button id='C3button' style={btn} onClick={this.props.toggle4}>I'm ready to sign up!</button>
              </div>
            </section>
            </div>
          </div>
        )}
      </Spring>
    )
  }
}

const c2Style = {
  background: 'rgba(138, 170, 229, 0.9)',
  padding: '0' ,
  margin: '0 0 50px 0'
}

const btn = {
  background: '#000A85',
  color:'#D3EFFD',
  border:'none',
  padding:'1rem 2rem',
  margin: '15px 2px',
  borderRadius: '9px',
  borderSize: '0',
  float:'right'

}

export default Component3
