import React, { Component } from 'react'
import { Spring } from 'react-spring';

export class Component2 extends Component {
  render() {
    return (
      <Spring
        from={{ opacity: 0, marginTop:-680}}
        to={{opacity: 1, marginTop:50}}
        config={{ delay: 1000, duration: 1000}}
        >
        {props => (
          <div style={props}>
            <div style={c2Style}>
              <section id="boxes">
                <div class="container">
                  <div class="box">
                    <img src={require("./images/fan_blade.png")} alt="this is something" style={{width:'95%'}}/>
                    <h3>Customized lectures</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ut nunSenserit ab excepteur si iis in reprehenderit hic incididunt voluptatibus o
                      nostrud, vidisse se fugiat. Ea constias ab offendit, de sint hic amet. An aliqua
                      laborum, appellat culpa consequat, et incurreret iudicem, pariatur si
                      dolor iudicem.Veniam arbitror singulis. Quibusdam o duis. Dolore ingeniis nam
                      illustriora, si commodo et nostrud se consequat philosophari, se cillum
                      sed eram.</p>
                  </div>
                  <div class="box">
                    <img src={require("./images/plane.jpg")} alt="this is something" style={{width:'95%'}}/>
                    <h3>Test your own materials</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ut nunSenserit ab excepteur si iis in reprehenderit hic incididunt voluptatibus o
                      nostrud, vidisse se fugiat. Ea constias ab offendit, de sint hic amet. An aliqua
                      laborum, appellat culpa consequat, et incurreret iudicem, pariatur si
                      dolor iudicem.Veniam arbitror singulis. Quibusdam o duis. Dolore ingeniis nam
                      illustriora, si commodo et nostrud se consequat philosophari, se cillum
                      sed eram.</p>
                  </div>
                </div>
              </section>
            <button style={btn} onClick={this.props.toggle}>Get to know SimAcad</button>
            </div>
          </div>
        )}
      </Spring>
    )
  }
}

const c2Style = {
  background: 'rgba(138, 170, 229, 0.9)',
  padding: '0' ,
  margin: '50px 0 50px 0'
}

const btn = {
  background: '#000A85',
  color:'#D3EFFD',
  padding:'1rem 2rem',
  border:'none',
  margin: '15px 2px',
  borderRadius: '9px',
  borderSize: '0'
}

export default Component2
