import React from 'react';
import { Spring } from 'react-spring';
import { Trail } from 'react-spring';

export default function Component1() {
  return (
    <Spring
      from={{ opacity: 0, marginLeft: -500}}
      to={{opacity: 1, marginLeft:0}}
      >
      {props => (
        <div style={props}>
          <div style={c1Style}>
          <section id="showcase">
            <div class='container'>
                <Trail
                config={{delay:1500}}
                items={items}
                from={{ opacity:0, transform: 'translate3d(0,-200px,0)' }}
                to={{ opacity:1, transform: 'translate3d(0,0,0)' }}>
                {item => props =>
                  <div class='Title' style={props}>{item}</div>
                }
                </Trail>
              <p>Optimize your workforce with sophisticated application knowledge </p>
            </div>
          </section>
          <section id="information">
            <div class="container">
              <h1>Interested? Let us know!</h1>
              <form>
                <input type="email" placeholder="Enter Email..."/>
                <button type="submit" class="button_1">Teach me!</button>
              </form>
            </div>
          </section>
          </div>
        </div>
      )}
    </Spring>
  )
}

const c1Style = {
  background: 'rgba(138, 170, 229, 0.9)',
  padding: '0' ,
  margin: '0 0 30px 0'
}


const items = ['FEA'," Simulation ","Academy"]
